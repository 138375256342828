<div class="pt-3 pb-3 d-flex">
  <form [formGroup]="searchForm" class="col-12 p-0 d-flex">
    <div class="col-4 col-md-3 p0">
      <app-form-field>
        <input
          appInput
          type="text"
          appAutofocus
          placeholder="{{ 'search.input_placeholder_l' | translate }}"
          class="form-control"
          formControlName="searchValue"
        />
        <app-button
          class="search-btn"
          [type]="'context-menu'"
          [elevationHoverEffect]="false"
          (clickEvent)="onSearch()"
          [iconLeft]="'search'"
          [buttonType]="'submit'"
        >
        </app-button>
      </app-form-field>
    </div>
    <p class="mb-0">
      <ngb-alert [dismissible]="false">
        <strong>Scroll this table left and right!</strong>
      </ngb-alert>
    </p>
  </form>
</div>

@if (!(isLoading$ | async)?.pending) {
  @if ((users$ | async) && (users$ | async)?.length) {
    <div class="table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            @for (header of headerArray; track header) {
              <th scope="col">{{ header | translate }}</th>
            }
          </tr>
        </thead>
        <tbody>
          @for (user of users$ | async; track user; let i = $index) {
            <tr>
              <th scope="col">
                {{
                  i +
                    pageSize * ((pagination$ | async)?.page - 1) +
                    1 +
                    pageSize
                }}
              </th>
              <td>{{ user?.email }}</td>
              <td>{{ user?.id }}</td>
              <td>
                <img
                  (click)="changeEnabled(user.id, user.enabled)"
                  src="/assets/images/icons/{{
                    user.enabled ? 'unlock-icon.svg' : 'lock-icon.svg'
                  }}"
                />
              </td>
              <td>
                <a
                  class="customer-id__link"
                  (click)="onCustomerId(user.customer.id)"
                  >{{ user?.customer?.id }}</a
                >
              </td>
              <td>{{ (user?.lastLogin | appDateTime) || '-' }}</td>
              <td>{{ user?.usertype }}</td>
              <td>{{ user?.customer?.name }}</td>
              <td>{{ user?.profile?.firstname }} {{ user?.profile?.name }}</td>
              <td>
                <app-context-menu>
                  <div menu-content>
                    <app-context-menu-item (clickEvent)="onEditUser(user.id)">
                      {{ 'table.edit_a' | translate }}
                    </app-context-menu-item>
                    <app-context-menu-item
                      (clickEvent)="impersonateUser(user.email)"
                    >
                      {{ 'table.login_as_user_a' | translate }}
                    </app-context-menu-item>
                  </div>
                </app-context-menu>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  } @else {
    <app-no-content
      headline="general.no_data_headline_l"
      message="general.no_data_message_l"
    ></app-no-content>
  }
} @else {
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
}

<ng-template #loading>
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>

<div class="d-flex justify-content-between p-2">
  <app-pagination
    [page]="(pagination$ | async)?.page + 1"
    [pageSize]="pageSize"
    [collectionSize]="(pagination$ | async)?.totalElements"
    (pageChange)="onPageChange($event)"
  >
  </app-pagination>
</div>
